<template>
  <div class="window-container" :class="{ 'window-mobile': isDevice }">
    <vue-advanced-chat
      ref="chatWindow"
      :height="height"
      :theme="theme"
      :is-mobile="true"
      :styles="JSON.stringify(styles)"
      :current-user-id="currentUserId"
      :room-id="roomId"
      :rooms="JSON.stringify(loadedRooms)"
      :loading-rooms="loadingRooms"
      :rooms-loaded="roomsLoaded"
      :messages="JSON.stringify(messages)"
      :messages-loaded="messagesLoaded"
      :show-add-room="false"
      :room-message="roomMessage"
      :room-actions="JSON.stringify(roomActions)"
      :menu-actions="JSON.stringify(menuActions)"
      :message-selection-actions="JSON.stringify(messageSelectionActions)"
      :message-actions="JSON.stringify(messageActions)"
      :templates-text="JSON.stringify(templatesText)"
      @fetch-more-rooms="fetchMoreRooms"
      @fetch-messages="fetchMessages"
      @send-message="sendMessage($event.detail[0])"
      @edit-message="editMessage($event.detail[0])"
      @delete-message="deleteMessage($event.detail[0])"
      @open-file="openFile($event.detail[0])"
      @open-user-tag="openUserTag($event.detail[0])"
      @room-action-handler="menuActionHandler($event.detail[0])"
      @menu-action-handler="menuActionHandler($event.detail[0])"
      @message-selection-action-handler="messageSelectionActionHandler($event.detail[0])"
      @send-message-reaction="sendMessageReaction($event.detail[0])"
      @typing-message="typingMessage($event.detail[0])"
    >
      <div slot="rooms-empty">
        <div>No agents in contact!</div>
        <small style="font-size: 11px; padding: 2px 5px;"> You can connect with an agent using Chat button available on listing detail page.</small>
      </div>
      <div slot="no-room-selected" />
      <template v-if="getSelectedRoomAvatar" #room-header-avatar>
        <div style="display: flex; justify-content: center; align-items: center; margin-right: 12px;">
          <img v-lazy-load  :data-src="getSelectedRoomAvatar" style="max-height: 40px; max-width: 80px; height: auto; width: auto;" :alt="getSelectedRoomAvatar">
        </div>
      </template>
      <!-- <div
        v-for="message in messages"
        :slot="'message_' + message._id"
        :key="message._id"
      >
        New message container
      </div> -->
    </vue-advanced-chat>
  </div>
</template>

<script setup lang="ts">
import * as firestoreService from '../database/firestore';
import * as firebaseService from '../database/firebase';
import * as storageService from '../database/storage';
import { parseTimestamp, formatTimestamp } from '../utils/dates';
import {ComputedRef, Ref} from 'vue';
import {useAuthUserStore} from '~/store/authUser';

// EXPOSED METHODS Definition
//----------------------------------------------------------------------------------------------------------------------
defineExpose({
  triggerAutoEnquiry,
});

// EMIT Definition
//----------------------------------------------------------------------------------------------------------------------
const emit = defineEmits<{
  (event: 'update:hasUnreadMessages', payload: boolean): void;
}>();

// PROPS Definition
//----------------------------------------------------------------------------------------------------------------------
const props = defineProps({
  currentUserId: {
    type: String,
    required: true,
  },
  isChatOpen: {
    type: Boolean,
    default: false,
  },
  height: {
    type: String,
    default: '100%',
  },
  isDevice: {
    type: Boolean,
    default: false,
  },
  theme: {
    type: String,
    default: 'light',
  },
  hasUnreadMessages: {
    type: Boolean,
    default: false,
  },
});

// DATA Definition
//----------------------------------------------------------------------------------------------------------------------
const authUserStore = useAuthUserStore();
const chatWindow: Ref<HTMLElement | null> = ref(null);
const roomsPerPage: Ref<number> = ref(25);
const rooms: Ref<any[]> = ref([]);
const roomId: Ref<string> = ref('');
const startRooms: Ref<any> = ref(null);
const endRooms: Ref<any> = ref(null);
const roomsLoaded: Ref<boolean> = ref(false);
const loadingRooms: Ref<boolean> = ref(true);
const allUsers: Ref<any[]> = ref([]);
const loadingLastMessageByRoom: Ref<number> = ref(0);
const roomsLoadedCount: Ref<number> = ref(0);
const selectedRoom: Ref<any> = ref(null);
const messagesPerPage: Ref<number> = ref(25);
const messages: Ref<any[]> = ref([]);
const messagesLoaded: Ref<boolean> = ref(false);
const roomMessage: Ref<string> = ref('');
const lastLoadedMessage: Ref<any> = ref(null);
const previousLastLoadedMessage: Ref<any> = ref(null);
const roomsListeners: Ref<any[]> = ref([]);
const listeners: Ref<any[]> = ref([]);
const typingMessageCache: Ref<string> = ref('');
const roomActions: Ref<any[]> = ref([]);
const menuActions: Ref<any[]> = ref([
  { name: 'deleteRoom', title: 'Delete This Chat' },
]);
const messageSelectionActions: Ref<any[]> = ref([{ name: 'deleteMessages', title: 'Delete' }]);
const styles: Ref<any> = ref({ container: { borderRadius: '4px' } });
const templatesText: Ref<any[]> = ref([]);
const messageActions: Ref<{name: string; title: string}[]>=ref([
  {
    name: 'replyMessage',
    title: 'Reply',
  },
  {
    name: 'editMessage',
    title: 'Edit Message',
    onlyMe: true,
  },
  {
    name: 'deleteMessage',
    title: 'Delete Message',
    onlyMe: true,
  },
]);

// COMPUTED Definition
//----------------------------------------------------------------------------------------------------------------------
const loadedRooms: ComputedRef<any[]> = computed(() => {
  return rooms.value.slice(0, roomsLoadedCount.value);
});

const getSelectedRoomAvatar: ComputedRef<string | null> = computed(() => {
  if (!selectedRoom.value) return null;
  const selectedRoomObject = rooms.value.find(room => room.roomId === selectedRoom.value);
  return selectedRoomObject?.roomAvatarUrl || null;
});

// LIFE CYCLE Definition
//----------------------------------------------------------------------------------------------------------------------
onMounted(() => {
  fetchRooms();
  firebaseService.updateUserOnlineStatus(props.currentUserId);
});

watch(() => selectedRoom.value, () => {
  triggerAutoEnquiry();
});

watch(() => authUserStore.chatInitiationData?.roomId, () => {
  if (authUserStore.chatInitiationData?.roomId !== selectedRoom.value) {
    fetchRooms();
  }
});

// METHODS Definition
//----------------------------------------------------------------------------------------------------------------------
/**
 * Trigger auto enquiry
 */
function triggerAutoEnquiry () {
  if (authUserStore.chatInitiationData?.roomId && authUserStore.chatInitiationData?.url) {
    selectedRoom.value = authUserStore.chatInitiationData?.roomId;
    const message = `Enquiry of ${authUserStore?.chatInitiationData?.url}`;
    sendMessage({content: message, roomId: selectedRoom.value});
    authUserStore.setChatInitiationData(null);
    fetchRooms();
    firebaseService.updateUserOnlineStatus(props.currentUserId);
  }
}

/**
 * Reset rooms and messages
 */
function resetRooms() {
  loadingRooms.value = true;
  loadingLastMessageByRoom.value = 0;
  roomsLoadedCount.value = 0;
  rooms.value = [];
  roomsLoaded.value = true;
  startRooms.value = null;
  endRooms.value = null;
  roomsListeners.value.forEach(listener => listener());
  roomsListeners.value = [];
  resetMessages();
}

/**
 * Reset messages
 */
function resetMessages() {
  messages.value = [];
  messagesLoaded.value = false;
  lastLoadedMessage.value = null;
  previousLastLoadedMessage.value = null;
  listeners.value.forEach(listener => listener());
  listeners.value = [];
}

/**
 * Fetch rooms
 */
function fetchRooms() {
  resetRooms();
  fetchMoreRooms();
}

/**
 * Fetch more rooms
 */
async function fetchMoreRooms() {
  if (endRooms.value && !startRooms.value) {
    roomsLoaded.value = true;
    return;
  }

  const query = firestoreService.roomsQuery(props.currentUserId, roomsPerPage.value, startRooms.value);

  const { data, docs } = await firestoreService.getRooms(query);
  // this.incrementDbCounter('Fetch Rooms', data.length)

  roomsLoaded.value = data.length === 0 || data.length < roomsPerPage.value;

  if (startRooms.value) {
    endRooms.value = startRooms.value;
  }
  startRooms.value = docs[docs.length - 1];

  const roomUserIds: any[] = [];
  data.forEach((room: any) => {
    room.users.forEach((userId: any) => {
      const foundUser = allUsers.value.find(user => user?._id === userId);
      if (!foundUser && roomUserIds.indexOf(userId) === -1) {
        roomUserIds.push(userId);
      }
    });
  });

  // this.incrementDbCounter('Fetch Room Users', roomUserIds.length)
  const rawUsers: any[] = [];
  roomUserIds.forEach(userId => {
    const promise = firestoreService.getUser(userId);
    rawUsers.push(promise);
  });

  allUsers.value = [...allUsers.value, ...(await Promise.all(rawUsers))];

  const roomList: any = {};
  data.forEach((room: any) => {
    roomList[room.id] = { ...room, users: [] };

    room.users.forEach((userId: any) => {
      const foundUser = allUsers.value.find((user: any) => user?._id === userId);
      if (foundUser) {
        roomList[room.id].users.push(foundUser);
      }
    });
  });

  const formattedRooms: any[] = [];

  Object.keys(roomList).forEach(key => {
    const room = roomList[key];

    const roomContacts = room.users.filter((user: any) => user._id !== props.currentUserId);

    room.roomName = room.roomName || roomContacts.map((user: any) => user.username).join(', ') || 'Myself';

    const roomAvatarFromUser = roomContacts.length === 1 && roomContacts[0].avatar ? roomContacts[0].avatar : '/images/avatars/blank.webp';
    const roomAvatar = room.roomAvatarUrl || roomAvatarFromUser;

    formattedRooms.push({
      ...room,
      roomId: key,
      avatar: roomAvatar,
      index: room.lastUpdated.seconds,
      lastMessage: {
        content: 'Room created',
        timestamp: formatTimestamp(
          new Date(room.lastUpdated.seconds),
          room.lastUpdated,
        ),
      },
    });
  });

  rooms.value = rooms.value.concat(formattedRooms);
  formattedRooms.forEach(room => listenLastMessage(room));
  makeRoomsUnique();

  if (!rooms.value.length) {
    loadingRooms.value = false;
    roomsLoadedCount.value = 0;
  }

  listenUsersOnlineStatus(formattedRooms);
  listenRooms(query);
  // setTimeout(() => console.log('TOTAL', this.dbRequestCount), 2000)
}

/**
 * Remove duplicate rooms
 */
function makeRoomsUnique () {
  const uniqueRooms = [];
  const seenIds = new Set();

  for (const room of rooms.value) {
    if (!seenIds.has(room.id)) {
      seenIds.add(room.id);
      uniqueRooms.push(room);
    }
  }

  rooms.value = uniqueRooms;
}

/**
 * Listen users online status
 *
 * @param rooms
 */
function listenLastMessage (room: any) {
  const listener = firestoreService.listenLastMessage(
    room.roomId,
    (localMessages: any[]) => {
      // this.incrementDbCounter('Listen Last Room Message', messages.length)
      localMessages.forEach((message: any) => {
        const lastMessage = formatLastMessage(message, room);
        const roomIndex = rooms.value.findIndex(r => room.roomId === r.roomId);
        rooms.value[roomIndex].lastMessage = lastMessage;
        rooms.value = [...rooms.value];
      });
      if (loadingLastMessageByRoom.value < rooms.value.length) {
        loadingLastMessageByRoom.value++;

        if (loadingLastMessageByRoom.value === rooms.value.length) {
          loadingRooms.value = false;
          roomsLoadedCount.value = rooms.value.length;
        }
      }
    },
  );

  roomsListeners.value.push(listener);
}

/**
 * Listen users online status
 *
 * @param message
 * @param room
 */
function formatLastMessage (message: any, room: any) {
  if (!message.timestamp) return;

  let content = message.content;
  if (message.files?.length) {
    const file = message.files[0];
    content = `${file.name}.${file.extension || file.type}`;
  }

  const username = message.sender_id !== props.currentUserId ? room.users.find((user: any) => message.sender_id === user._id)?.username : '';

  return {
    ...message,
    ...{
      _id: message.id,
      content,
      senderId: message.sender_id,
      timestamp: formatTimestamp(new Date(message.timestamp.seconds * 1000), message.timestamp),
      username: username,
      distributed: true,
      seen: message.sender_id !== props.currentUserId ? message.seen : null,
      new: message.sender_id !== props.currentUserId && (!message.seen || !message.seen[props.currentUserId]),
    },
  };
}

/**
 * Listen users online status
 *
 * @param data
 */
function fetchMessages (data: any) {
  if (!data || !data.detail?.length) return;
  const { room, options } = data.detail[0];
  if (options?.reset) {
    resetMessages();
  }

  if (previousLastLoadedMessage.value && !lastLoadedMessage.value) {
    messagesLoaded.value = true;
    return;
  }

  selectedRoom.value = room.roomId;

  firestoreService.getMessages(room.roomId, messagesPerPage.value, lastLoadedMessage.value).then(({ data, docs }: any) => {
    // this.incrementDbCounter('Fetch Room Messages', messages.length)
    if (selectedRoom.value !== room.roomId) return;

    if (data.length === 0 || data.length < messagesPerPage.value) {
      setTimeout(() => {
        messagesLoaded.value = true;
      }, 0);
    }

    if (options?.reset) {
      messages.value = [];
    }

    data.forEach((message: any) => {
      const formattedMessage = formatMessage(room, message);
      messages.value.unshift(formattedMessage);
    });

    if (lastLoadedMessage.value) {
      previousLastLoadedMessage.value = lastLoadedMessage.value;
    }
    lastLoadedMessage.value = docs[docs.length - 1];

    listenMessages(room);
  });
}

function listenMessages (paramRoom: any) {
  const listener = firestoreService.listenMessages(
    paramRoom.roomId,
    lastLoadedMessage.value,
    previousLastLoadedMessage.value,
    (queryMessages: any[]) => {
      queryMessages.forEach((queryMessage) => {
        const formattedMessage = formatMessage(paramRoom, queryMessage);
        const messageIndex = messages.value.findIndex(m => m._id === queryMessage.id);

        if (messageIndex === -1) {
          messages.value = messages.value.concat([formattedMessage]);
        } else {
          messages.value[messageIndex] = formattedMessage;
          messages.value = [...messages.value];
        }

        markMessagesSeen(paramRoom, queryMessage);
      });

      // Set has unread messages
      setHasUnreadMessages();
    },
  );
  listeners.value.push(listener);
}

/**
 * Set has unread messages
 */
function setHasUnreadMessages () {
  emit('update:hasUnreadMessages', !!rooms.value.find(room => !!room.lastMessage.new));
}

/**
 * Listen users online status
 *
 * @param room
 * @param message
 */
function markMessagesSeen(room: any, message: any) {
  if (props.isChatOpen && room.id === selectedRoom.value && message.sender_id !== props.currentUserId && (!message.seen || !message.seen[props.currentUserId])) {
    firestoreService.updateMessage(room.roomId, message.id, {
      [`seen.${props.currentUserId}`]: new Date(),
    });

    // Set has unread messages
    setHasUnreadMessages();
  }
}

/**
 * Listen users online status
 *
 * @param room
 * @param message
 */
function formatMessage(room: any, message: any) {
  // const senderUser = room.users.find(user => user._id === message.sender_id)
  const formattedMessage = {
    ...message,
    ...{
      senderId: message.sender_id,
      _id: message.id,
      seconds: message.timestamp.seconds,
      timestamp: parseTimestamp(message.timestamp, 'HH:mm'),
      date: parseTimestamp(message.timestamp, 'DD MMMM YYYY'),
      username: room.users.find((user: any) => message.sender_id === user._id)?.username,
      // avatar: senderUser ? senderUser.avatar : null,
      distributed: true,
    },
  };

  if (message.replyMessage) {
    formattedMessage.replyMessage = {
      ...message.replyMessage,
      ...{
        senderId: message.replyMessage.sender_id,
      },
    };
  }

  return formattedMessage;
}

async function sendMessage({ content, roomId, files, replyMessage }: any) {
  const message: any = {
    sender_id: props.currentUserId,
    content,
    timestamp: new Date(),
  };

  if (files) {
    message.files = formattedFiles(files);
  }

  if (replyMessage) {
    message.replyMessage = {
      _id: replyMessage._id,
      content: replyMessage.content,
      sender_id: replyMessage.senderId,
    };

    if (replyMessage.files) {
      message.replyMessage.files = replyMessage.files;
    }
  }

  const { id } = await firestoreService.addMessage(roomId, message);

  if (files) {
    for (let index = 0; index < files.length; index++) {
      await uploadFile({ file: files[index], messageId: id, roomId });
    }
  }

  firestoreService.updateRoom(roomId, { lastUpdated: new Date() });
}

async function editMessage({ messageId, newContent, roomId, files }: any) {
  const newMessage: any = { edited: new Date() };
  newMessage.content = newContent;

  if (files) {
    newMessage.files = formattedFiles(files);
  } else {
    newMessage.files = firestoreService.deleteDbField;
  }

  await firestoreService.updateMessage(roomId, messageId, newMessage);

  if (files) {
    for (let index = 0; index < files.length; index++) {
      if (files[index]?.blob) {
        await uploadFile({ file: files[index], messageId, roomId });
      }
    }
  }
}

async function deleteMessage({ message, roomId }: any) {
  await firestoreService.updateMessage(roomId, message._id, {
    deleted: new Date(),
  });

  const { files } = message;

  if (files) {
    files.forEach((file: any) => {
      storageService.deleteFile(props.currentUserId, message._id, file);
    });
  }
}

async function uploadFile({ file, messageId, roomId }: any) {
  return new Promise(resolve => {
    let type = file.extension || file.type;
    if (type === 'svg' || type === 'pdf') {
      type = file.type;
    }

    storageService.listenUploadImageProgress(
      props.currentUserId,
      messageId,
      file,
      type,
      (progress: any) => {
        updateFileProgress(messageId, file.localUrl, progress);
      },
      () => {
        resolve(false);
      },
      async (url: string) => {
        const message = await firestoreService.getMessage(roomId, messageId);

        message.files.forEach((f: any) => {
          if (f.url === file.localUrl) {
            f.url = url;
          }
        });

        await firestoreService.updateMessage(roomId, messageId, {
          files: message.files,
        });
        resolve(true);
      },
    );
  });
}

function updateFileProgress (messageId: string, fileUrl: string, progress: any) {
  const message = messages.value.find(message => message._id === messageId);

  if (!message || !message.files) return;

  message.files.find((file: any) => file.url === fileUrl).progress = progress;
  messages.value = [...messages.value];
}

function formattedFiles (files: any) {
  const formattedFiles: any[] = [];

  files.forEach((file: any) => {
    const messageFile: any = {
      name: file.name,
      size: file.size,
      type: file.type,
      extension: file.extension || file.type,
      url: file.url || file.localUrl,
    };

    if (file.audio) {
      messageFile.audio = true;
      messageFile.duration = file.duration;
    }

    formattedFiles.push(messageFile);
  });

  return formattedFiles;
}

function openFile({ file }: any) {
  window.open(file.file.url, '_blank');
}

async function openUserTag ({ user }: any) {
  let localRoomId;

  rooms.value.forEach((queryRoom: any) => {
    if (queryRoom.users.length === 2) {
      const userId1 = queryRoom.users[0]._id;
      const userId2 = queryRoom.users[1]._id;
      if ((userId1 === user._id || userId1 === props.currentUserId) && (userId2 === user._id || userId2 === props.currentUserId)) {
        localRoomId = queryRoom.roomId;
      }
    }
  });

  if (localRoomId) {
    roomId.value = localRoomId;
    return;
  }

  const query1 = await firestoreService.getUserRooms(props.currentUserId, user._id);

  if (query1.data.length) {
    return loadRoom(query1);
  }

  const query2 = await firestoreService.getUserRooms(user._id, props.currentUserId);

  if (query2.data.length) {
    return loadRoom(query2);
  }

  const users =
    user._id === props.currentUserId
      ? [props.currentUserId]
      : [user._id, props.currentUserId];

  const room = await firestoreService.addRoom({
    users: users,
    lastUpdated: new Date(),
  });

  roomId.value = room.id;
  fetchRooms();
}

async function loadRoom (query: any) {
  query.forEach(async (queryRoom: any) => {
    if (loadingRooms.value) return;
    await firestoreService.updateRoom(queryRoom.id, { lastUpdated: new Date() });
    roomId.value = queryRoom.id;
    fetchRooms();
  });
}

function menuActionHandler({ action, roomId }: any) {
  switch (action.name) {
    case 'deleteRoom':
      return deleteRoom(roomId);
  }
}

function messageSelectionActionHandler({ action, messages, roomId }: any) {
  switch (action.name) {
    case 'deleteMessages':
      messages.forEach((message: any) => {
        deleteMessage({ message, roomId });
      });
  }
}

async function sendMessageReaction ({ reaction, remove, messageId, roomId }: any) {
  firestoreService.updateMessageReactions(
    roomId,
    messageId,
    props.currentUserId,
    reaction.unicode,
    remove ? 'remove' : 'add',
  );
  updateMessage(messageId, roomId);
}

/**
 * Update message
 *
 * @param messageId
 * @param roomId
 */
async function updateMessage (messageId: string, roomId: string) {
  if (roomId !== selectedRoom.value) return;
  const thisMessageIndex = messages.value.findIndex((message: any) => message._id === messageId);
  if (thisMessageIndex > -1) {
    messages.value[thisMessageIndex] = formatMessage(
      rooms.value.find((room: any) => room.roomId === roomId),
      await firestoreService.getMessage(roomId, messageId),
    );
  }
}

function typingMessage({ message, roomId }: any) {
  if (roomId) {
    if (message?.length > 1) {
      typingMessageCache.value = message;
      return;
    }

    if (message?.length === 1 && typingMessageCache.value) {
      typingMessageCache.value = message;
      return;
    }

    typingMessageCache.value = message;

    firestoreService.updateRoomTypingUsers(
      roomId,
      props.currentUserId,
      message ? 'add' : 'remove',
    );
  }
}

async function listenRooms (query: any) {
  const listener = firestoreService.listenRooms(query, (callbackRooms: any[]) => {
    // this.incrementDbCounter('Listen Rooms Typing Users', callbackRooms.length)
    callbackRooms.forEach((thisRoom: any) => {
      const foundRoom = rooms.value.find((r: any) => r.roomId === thisRoom.id);
      if (foundRoom) {
        foundRoom.typingUsers = thisRoom.typingUsers;
        foundRoom.index = thisRoom.lastUpdated.seconds;
      }
    });
  });
  roomsListeners.value.push(listener);
}

function listenUsersOnlineStatus (paramRooms: any) {
  paramRooms.forEach((paramRoom: any) => {
    paramRoom.users.forEach((user: any) => {
      const listener = firebaseService.firebaseListener(
        firebaseService.userStatusRef(user._id),
        snapshot => {
          if (!snapshot || !snapshot.val()) return;

          const lastChanged = formatTimestamp(
            new Date(snapshot.val().lastChanged),
            new Date(snapshot.val().lastChanged),
          );

          user.status = { ...snapshot.val(), lastChanged };

          const roomIndex = rooms.value.findIndex(
            r => paramRoom.roomId === r.roomId,
          );

          rooms.value[roomIndex] = paramRoom;
          rooms.value = [...rooms.value];
        },
      );
      roomsListeners.value.push(listener);
    });
  });
}

async function deleteRoom (roomId: string) {
  await firestoreService.deleteRoom(roomId);
  fetchRooms();
}
</script>
