<template>
  <div id="__clou-default-layout" class="clou-default-layout">
    <!-- Test mode bar -->
    <div v-if="config.public.appEnv !== 'production'" class="__test-mode-wrapper">
      <div class="__test-mode-wrapper__content">
        Test Mode - {{ config.public.appEnv }}
      </div>
    </div>

    <LayoutHeader />
    <main>
      <NuxtPage />
    </main>
    <LayoutFooter />

    <!-- Chat -->
    <CommonChat />
  </div>
</template>

<script lang="ts" setup>
const config = useRuntimeConfig();
</script>

<style lang="scss">
.__test-mode-wrapper {
  top: 0;
  position: fixed;
  width: 100%;
  left: 0;
  height: 5px;
  background: #fdb529;
  z-index: 9999;
  box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.5);

  &__content {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: fit-content;
    height: fit-content;
    padding: 2px 12px 1px;
    text-transform: capitalize;
    text-align: center;
    background: #fdb529;
    z-index: 99999;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0.5);
    font-size: 14px;
  }
}
</style>
