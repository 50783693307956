import { firestoreDb } from './index';

import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  deleteField,
  doc,
  endAt,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  startAfter,
  startAt,
  updateDoc,
  where,
} from 'firebase/firestore';

const USERS_PATH = 'users';
const ROOMS_PATH = 'chatRooms';
const MESSAGES_PATH = 'messages';
const MESSAGE_PATH = (roomId: string) => {
  return `${ROOMS_PATH}/${roomId}/${MESSAGES_PATH}`;
};

const TIMESTAMP_FIELD = 'timestamp';
const LAST_UPDATED_FIELD = 'lastUpdated';
const TYPING_USERS_FIELD = 'typingUsers';
const MESSAGE_REACTIONS_FIELD = 'reactions';
const ROOM_USERS_FIELD = 'users';

export const firestoreListener = onSnapshot;
export const deleteDbField = deleteField();

const getDocuments = (query: any) => {
  return getDocs(query).then(docs => {
    return { data: formatQueryDataArray(docs), docs: docs.docs };
  });
};

const getDocument = (ref: any) => {
  return getDoc(ref).then(doc => formatQueryDataObject(doc));
};

const addDocument = (ref: any, data: any) => {
  return addDoc(ref, data);
};

const setDocument = (path: string, docId: string, data: any) => {
  return setDoc(doc(firestoreDb, path, docId), data);
};

const updateDocument = (ref: any, data: any) => {
  return updateDoc(ref, data);
};

const deleteDocument = (ref: any, docId: string) => {
  return deleteDoc(doc(firestoreDb, ref, docId));
};

// USERS
const usersRef = collection(firestoreDb, USERS_PATH);

const userRef = (userId: string) => {
  return doc(firestoreDb, USERS_PATH, userId);
};

export const getAllUsers = () => {
  return getDocuments(query(usersRef));
};

export const getUser = (userId: string) => {
  return getDocument(userRef(userId));
};

export const addUser = (data: any) => {
  return addDocument(usersRef, data);
};

export const addIdentifiedUser = (userId: string, data: any) => {
  return setDocument(USERS_PATH, userId, data);
};

export const updateUser = (userId: string, data: any) => {
  return updateDocument(userRef(userId), data);
};

export const deleteUser = (userId: string) => {
  return deleteDocument(USERS_PATH, userId);
};

// ROOMS
const roomsRef = collection(firestoreDb, ROOMS_PATH);

const roomRef = (roomId: string) => {
  return doc(firestoreDb, ROOMS_PATH, roomId);
};

export const roomsQuery = (currentUserId: string, roomsPerPage: number, lastRoom: string) => {
  if (lastRoom) {
    return query(
      roomsRef,
      where(USERS_PATH, 'array-contains', currentUserId),
      orderBy(LAST_UPDATED_FIELD, 'desc'),
      limit(roomsPerPage),
      startAfter(lastRoom),
    );
  } else {
    return query(
      roomsRef,
      where(USERS_PATH, 'array-contains', currentUserId),
      orderBy(LAST_UPDATED_FIELD, 'desc'),
      limit(roomsPerPage),
    );
  }
};

export const getAllRooms = () => {
  return getDocuments(query(roomsRef));
};

export const getRooms = (query: any) => {
  return getDocuments(query);
};

export const addRoom = (data: any) => {
  return addDocument(roomsRef, data);
};

export const updateRoom = (roomId: string, data: any) => {
  return updateDocument(roomRef(roomId), data);
};

export const deleteRoom = (roomId: string) => {
  return deleteDocument(ROOMS_PATH, roomId);
};

export const getUserRooms = (currentUserId: string, userId: string) => {
  return getDocuments(
    query(roomsRef, where(USERS_PATH, '==', [currentUserId, userId])),
  );
};

export const addRoomUser = (roomId: string, userId: string) => {
  return updateRoom(roomId, {
    [ROOM_USERS_FIELD]: arrayUnion(userId),
  });
};

export const removeRoomUser = (roomId: string, userId: string) => {
  return updateRoom(roomId, {
    [ROOM_USERS_FIELD]: arrayRemove(userId),
  });
};

export const updateRoomTypingUsers = (roomId: string, currentUserId: string, action: string) => {
  const arrayUpdate = action === 'add' ? arrayUnion(currentUserId) : arrayRemove(currentUserId);

  return updateRoom(roomId, { [TYPING_USERS_FIELD]: arrayUpdate });
};

// MESSAGES
const messagesRef = (roomId: string) => {
  return collection(firestoreDb, MESSAGE_PATH(roomId));
};

const messageRef = (roomId: string, messageId: string) => {
  return doc(firestoreDb, MESSAGE_PATH(roomId), messageId);
};

export const getMessages = (roomId: string, messagesPerPage: number, lastLoadedMessage: any) => {
  if (lastLoadedMessage) {
    return getDocuments(
      query(
        messagesRef(roomId),
        orderBy(TIMESTAMP_FIELD, 'desc'),
        limit(messagesPerPage),
        startAfter(lastLoadedMessage),
      ),
    );
  } else if (messagesPerPage) {
    return getDocuments(
      query(
        messagesRef(roomId),
        orderBy(TIMESTAMP_FIELD, 'desc'),
        limit(messagesPerPage),
      ),
    );
  } else {
    return getDocuments(messagesRef(roomId));
  }
};

export const getMessage = (roomId: string, messageId: string) => {
  return getDocument(messageRef(roomId, messageId));
};

export const addMessage = (roomId: string, data: any) => {
  return addDocument(messagesRef(roomId), data);
};

export const updateMessage = (roomId: string, messageId: string, data: any) => {
  return updateDocument(messageRef(roomId, messageId), data);
};

export const deleteMessage = (roomId: string, messageId: string) => {
  return deleteDocument(MESSAGE_PATH(roomId), messageId);
};

export const listenRooms = (query: any, callback: any) => {
  return firestoreListener(query, (rooms: any) => {
    callback(formatQueryDataArray(rooms));
  });
};

export const paginatedMessagesQuery = (roomId: any, lastLoadedMessage: any, previousLastLoadedMessage: any) => {
  if (lastLoadedMessage && previousLastLoadedMessage) {
    return query(
      messagesRef(roomId),
      orderBy(TIMESTAMP_FIELD),
      startAt(lastLoadedMessage),
      endAt(previousLastLoadedMessage),
    );
  } else if (lastLoadedMessage) {
    return query(
      messagesRef(roomId),
      orderBy(TIMESTAMP_FIELD),
      startAt(lastLoadedMessage),
    );
  } else if (previousLastLoadedMessage) {
    return query(
      messagesRef(roomId),
      orderBy(TIMESTAMP_FIELD),
      endAt(previousLastLoadedMessage),
    );
  } else {
    return query(messagesRef(roomId), orderBy(TIMESTAMP_FIELD));
  }
};

export const listenMessages = (roomId: string, lastLoadedMessage: any, previousLastLoadedMessage: any, callback: any) => {
  return firestoreListener(
    paginatedMessagesQuery(
      roomId,
      lastLoadedMessage,
      previousLastLoadedMessage,
    ),
    messages => {
      callback(formatQueryDataArray(messages));
    },
  );
};

const formatQueryDataObject = (queryData: any) => {
  return { ...queryData.data(), id: queryData.id };
};

const formatQueryDataArray = (queryDataArray: any) => {
  const formattedData: any[] = [];

  queryDataArray.forEach((data: any) => {
    formattedData.push(formatQueryDataObject(data));
  });
  return formattedData;
};

const lastMessageQuery = (roomId: string) => {
  return query(messagesRef(roomId), orderBy(TIMESTAMP_FIELD, 'desc'), limit(1));
};

export const listenLastMessage = (roomId: string, callback: any) => {
  return firestoreListener(query(lastMessageQuery(roomId)), messages => {
    callback(formatQueryDataArray(messages));
  });
};

export const updateMessageReactions = (roomId: string, messageId: string, currentUserId: string, reactionUnicode: string, action: string) => {
  const arrayUpdate =
		action === 'add' ? arrayUnion(currentUserId) : arrayRemove(currentUserId);

  return updateMessage(roomId, messageId, {
    [`${MESSAGE_REACTIONS_FIELD}.${reactionUnicode}`]: arrayUpdate,
  });
};
