import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const config = {
  apiKey: 'AIzaSyATswUkc_ml4LlyZvay423AMBxeQj7W3GY',
  authDomain: 'newhomeschat-6c302.firebaseapp.com',
  projectId: 'newhomeschat-6c302',
  storageBucket: 'newhomeschat-6c302.appspot.com',
  messagingSenderId: '17195849793',
  appId: '1:17195849793:web:62e6b9b86a01c630498ec0',
  measurementId: 'G-4Z9JG43TZW',
};

initializeApp(config);

export const firestoreDb = getFirestore();
export const realtimeDb = getDatabase();
export const storage = getStorage();

