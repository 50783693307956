<template>
  <div class="common-chat-wrapper" :class="{'--open': isChatOpen}">
    <div v-if="isUserLoggedIn" class="common-chat" :style="{ height: isChatOpen ? chatWindowHeight : '0' }">
      <div class="error-wrapper" :class="{'--has-error': hasError}">
        Unable to initiate a chat! Please try to email instead.
      </div>
      <CommonChatChatContainer
        v-if="currentUserId"
        ref="chatRef"
        v-model:has-unread-messages="hasUnreadMessages"
        class="common-chat__chat-container"
        :current-user-id="currentUserId"
        :is-chat-open="isChatOpen"
        :is-device="isDevice"
        :height="chatWindowHeight"
        @show-demo-options="showDemoOptions = $event"
      />
    </div>
    <div class="chat-trigger-btn no-highlight" @click="triggerChat">
      <div v-if="hasUnreadMessages && !isChatOpen" class="wave-effect" />
      <img v-lazy-load  v-if="isChatOpen" :data-src="closeIcon" alt="close message icon">
      <img v-lazy-load  v-else :data-src="messageIcon" alt="message icon">
      <span v-if="hasUnreadMessages && !isChatOpen" class="__has-unread-message" />
      <!--      <span v-if="hasUnreadMessages" class="__new-message-count">-->
      <!--        2-->
      <!--      </span>-->
    </div>
  </div>
</template>

<script setup lang="ts">
import {ComputedRef, Ref} from 'vue';
import {useAuthUserStore} from '~/store/authUser';
import messageIcon from '~/assets/images/icons/message-dark.webp';
import closeIcon from '~/assets/images/icons/close.webp';
import {useLayoutStore} from '~/store/layout';
import {useRouter} from '#app';

// DATA Definition
//----------------------------------------------------------------------------------------------------------------------
const currentUserId: Ref<string | null>  = ref(null);
const isDevice: Ref<boolean> = ref(false);
const showDemoOptions: Ref<boolean> = ref(true);
const authUserStore = useAuthUserStore();
const config = useRuntimeConfig();
const isChatOpen: Ref<boolean> = ref(false);
const layoutStore = useLayoutStore();
const chatRef: Ref<HTMLElement | null> = ref(null);
const hideChatContainer: Ref<boolean> = ref(false);
const hasUnreadMessages: Ref<boolean> = ref(false);
const hasError: Ref<boolean> = ref(false);
const {$logout}: any = useNuxtApp();
const router = useRouter();

/**
 * Component MOUNTED!
 */
onMounted(() => {
  if (authUserStore.user) {
    currentUserId.value = `${config.public.appEnv}-${authUserStore.user.did}`;
  }
});

watch(() => layoutStore.isChatOpen, () => {
  if (isDevice.value !== layoutStore.isChatOpen) {
    isChatOpen.value = layoutStore.isChatOpen;
  }

  if (layoutStore.isChatOpen && chatRef.value) {
    (chatRef.value as any).triggerAutoEnquiry();
  }

  if (authUserStore?.chatInitiationData?.hasError ?? false) {
    setHasError();
  }
});

watch(() => isChatOpen.value, () => {
  if (isChatOpen.value !== layoutStore.isChatOpen) {
    layoutStore.setChatOpen(isChatOpen.value);
  }

  if (isChatOpen.value) {
    hideChatContainer.value = false;
  } else {
    setTimeout(() => {
      hideChatContainer.value = true;
    }, 300);
  }
});

/**
 * Chat window height
 */
const chatWindowHeight: ComputedRef<string> = computed(() => {
  const height = window.innerHeight - 100;
  return (height > 600 ? 720 : height) + 'px';
});

/**
 * Check is the user logged in
 */
const isUserLoggedIn: ComputedRef<boolean> = computed(() => {
  return !!authUserStore.user && authUserStore.token && authUserStore.user.did;
});

/**
 * Trigger chat
 */
function triggerChat () {
  if (isUserLoggedIn.value) {
    isChatOpen.value = !isChatOpen.value;
  } else {
    if (authUserStore.token) {
      $logout();
    }
    router.push('/auth/login');
  }
}

/**
 * Trigger error banner
 */
function setHasError () {
  hasError.value = true;

  setTimeout(() => {
    hasError.value = false;
  }, 5000);
}
</script>

<style lang="scss" scoped>
.common-chat-wrapper {
  position: fixed;
  bottom: 38px;
  right: 50px;
  z-index: 999;
  transition: all 0.2s ease-in-out;

  .chat-trigger-btn {
    float: right;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #f07d7b;
    box-shadow: 0 -1px 20px 4px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    z-index: 998;
    display: flex;
    align-items: center;
    justify-content: right;
    position: relative;

    &:hover {
      box-shadow: 0 -1px 28px 2px rgba(0, 0, 0, 0.4);
      transform: scale(1.1);
    }

    img {
      width: 16px;
      height: 16px;
      margin: auto;
      display: block;
    }

    .__has-unread-message {
      position: absolute;
      background: rgb(92, 82, 198);
      width: 10px;
      height: 10px;
      border-radius: 50%;
      box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      top: 8px;
      right: 8px;
    }

    .__new-message-count {
      position: absolute;
      background: #fff;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      top: 6px;
      right: 6px;
    }
  }

  .common-chat {
    width: 0;
    height: 0;
    max-width: 100vw;
    max-height: 100vh;
    opacity: 0;
    overflow: hidden;
    border-radius: 8px;
    z-index: 999;
    box-shadow: 0 1px 20px 4px rgba(0,0,0,0.4);
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out, opacity 0.25s ease-in-out;

    .error-wrapper {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 11;
      background: #ba1515bd;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      backdrop-filter: blur(2px);
      -webkit-backdrop-filter: blur(2px);
      opacity: 0;
      height: 0;
      transition: all 0.3s ease-in-out;

      &.--has-error {
        opacity: 1;
        height: fit-content;
        padding: 16px;
        transition: all 0.3s ease-in-out;
      }
    }
  }

  .wave-effect {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: rgba(255,255,255,0.6);
    animation: wave-animation 3s infinite ease-out;
  }

  @keyframes wave-animation {
    0% {
      transform: scale(0.5);
      opacity: 0.5;
    }
    100% {
      transform: scale(1.5);
      opacity: 0;
    }
  }

  &.--open {
    bottom: 12px;
    right: 0;
    transition: all 0.2s ease-in-out;

    .common-chat {
      opacity: 1;
      width: 620px; // set your desired width here
      //height: 720px; // or set your desired height here
      max-width: 100%;
      max-height: 100vh;  // Define a suitable max-height that your chat container can reach.
      transition: width 0.3s ease-in-out, height 0.3s ease-in-out, opacity 0.25s 0.05s ease-in-out;
    }
  }

  @media screen and (max-width: $grid-sm) {
    .common-chat {
      max-width: 100vw !important;
      max-height: 100vh !important;
    }
  }
}
</style>
