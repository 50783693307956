import { storage } from '~/components/common/chat/database/index';

import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';

const FILES_PATH = 'files';

const fileRef = (currentUserId: string, messageId: string, fileName: string) => {
  return ref(storage, `${FILES_PATH}/${currentUserId}/${messageId}/${fileName}`);
};

export const deleteFile = (currentUserId: string, messageId: string, file: any) => {
  return deleteObject(
    fileRef(
      currentUserId,
      messageId,
      `${file.name}.${file.extension || file.type}`,
    ),
  );
};

export const getFileDownloadUrl = (ref: any) => {
  return getDownloadURL(ref);
};

export const uploadFileTask = (currentUserId: string, messageId: string, file: any, type: any) => {
  const uploadFileRef = fileRef(
    currentUserId,
    messageId,
    `${file.name}.${type}`,
  );

  return uploadBytesResumable(uploadFileRef, file.blob, {
    contentType: type,
  });
};

export const listenUploadImageProgress = (
  currentUserId: string,
  messageId: string,
  file: any,
  type: any,
  callback: any,
  error: any,
  success: any,
) => {
  const uploadTask = uploadFileTask(currentUserId, messageId, file, type);

  uploadTask.on(
    'state_changed',
    snap => {
      const progress = Math.round(
        (snap.bytesTransferred / snap.totalBytes) * 100,
      );
      callback(progress);
    },
    _error => {
      error(_error);
    },
    async () => {
      const url = await getFileDownloadUrl(uploadTask.snapshot.ref);
      success(url);
    },
  );
};
